import Vue from "vue"
import VueRouter from "vue-router"

import register from "../views/register.vue"
import forPoints from "../views/forPoints/forPoints.vue"
Vue.use(VueRouter)

const routes = [
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/register"),
  },
   {
      path: "/forPoints",
      name: "forPoints",
      component: () =>
        import(/* webpackChunkName: "about" */ "@/views/forPoints/forPoints"),
    }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

export default router
