<template>
	<div id="register">
	
	</div>
</template>

<script>
	export default {}
</script>

<style scoped lang="scss">
	* {
		margin: 0;
		height: 0;
	}

	#register {}
</style>
